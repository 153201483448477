export const LIBRARY_MENU_ITEMS = [
  {
    id: 1,
    title: 'societies',
    href: '/societies',
  },
  {
    id: 2,
    title: 'past conferences',
    href: '/library/past-conferences',
  },
  {
    id: 3,
    title: 'presentations',
    href: '/library',
  },
  {
    id: 4,
    title: 'speakers',
    href: '/speakers',
  },
];

export interface INavigationItems {
  id: number | string;
  href: string;
  title: string;
}
