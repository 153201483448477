import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Menu,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { useResources } from 'datx-jsonapi-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { INavigationItems, LIBRARY_MENU_ITEMS } from '@/components/ui/shared/headers/CoreHeader/components/constants';
import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { queryAllOriginalsSeries } from '@/queries/originals-series';
import { OriginalsSeries } from '@/resources/OriginalsSeries';

export const LibraryMenuMobile: FC = () => {
  const { data } = useResources(queryAllOriginalsSeries({ collection: 'false' }));
  const router = useRouter();

  function getHref(original: OriginalsSeries) {
    return `/originals/${original.id}-${original.slug}`;
  }

  return (
    <Flex display={{ base: 'block', lg: 'none' }}>
      <Menu variant="navigation" colorScheme="blackAlpha" placement="bottom-end">
        <Accordion allowMultiple minWidth={292} border={0}>
          <AccordionItem border={0}>
            <h2>
              <AccordionButton
                border={0}
                fontWeight={700}
                px={{ base: '20px', md: '40px' }}
                py={3}
                pr={{ base: '28px', md: '20px' }}
              >
                <Box as="span" flex="1" textAlign="left" _hover={{ background: 'none', textDecoration: 'underline' }}>
                  library
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {LIBRARY_MENU_ITEMS.slice(0, 3).map((item: INavigationItems) => (
                <NextLink href={item.href} passHref key={item.id} legacyBehavior>
                  <NavLink
                    as={MenuItem}
                    isActiveLink={item.href === router.pathname}
                    _hover={{ background: 'none', textDecoration: 'underline' }}
                  >
                    {item.title}
                  </NavLink>
                </NextLink>
              ))}
              <Accordion allowMultiple>
                <AccordionItem pl={{ base: 1, md: 6 }} border="none">
                  <AccordionButton display="flex" justifyContent="space-between" py={3} fontWeight="semibold">
                    originals
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel display="flex" flexDir="column" p={0}>
                    {(data || []).map((original) => (
                      <Text
                        as={NextLink}
                        fontFamily="heading"
                        fontWeight="semibold"
                        tabIndex={-1}
                        href={getHref(original)}
                        key={original.id}
                        pl={8}
                        py={3}
                      >
                        {original.author.firstName} {original.author.lastName}
                      </Text>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              {LIBRARY_MENU_ITEMS.slice(3).map((item: INavigationItems) => (
                <NextLink href={item.href} passHref key={item.id} legacyBehavior>
                  <NavLink
                    as={MenuItem}
                    isActiveLink={item.href === router.pathname}
                    _hover={{ background: 'none', textDecoration: 'underline' }}
                  >
                    {item.title}
                  </NavLink>
                </NextLink>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Menu>
    </Flex>
  );
};
